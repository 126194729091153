import React from 'react';

const LinkTitle = () => {
    return (                    
        <a title="About us" className="transform-scale-h" href={ `${ process.env.PUBLIC_URL + "/about-us" }` }>
            Über uns<i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
        </a>
    );
};
  
const TextTitle = () => {
    return ( <div title="About us">Über uns</div> );
};

const AboutUsTitle = ( props ) => {

const isHomepage = props.isHomepage;

    if ( isHomepage ) {
        return <LinkTitle />;
    }
        return <TextTitle />;
};

const AboutUsPrimary = ( props ) => {
    return (
        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="title">
                <h2>
                    <AboutUsTitle isHomepage={ props.isHomepage } />
                </h2>
            </div>

              <div className="text">
                  <p>Die Firma TÜREGÜN Transport GmbH ist ein Schweizer Transportunternehmen mit Sitz
                    in Basel.<br /><br />
                    Unsere Dienstleistungen bestehen aus dem Transport und Einlagern von
                    Gütern innerhalb der Schweiz, so wie Italien und dem grenznahen Ausland.
                    Ebenso sind wir im Möbeltransport tätig und führen Umzüge in der Schweiz,
                    so wie in Italien durch.
                    
                    </p>
            </div>

            <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default">
                <div className="list-group-item">
                    <h4 className="text-secondary">5</h4>
                    <p>Countries</p>
                </div>

                <div className="list-group-item">
                    <h4 className="text-secondary">1</h4>
                    <p>Offices</p>
                </div>

                <div className="list-group-item">
                    <h4 className="text-secondary">25</h4>
                    <p>Employees</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPrimary;
