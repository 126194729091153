import React, { Component } from 'react';
import { Link } from 'react-scroll';


class HomeHeaderMenuPrimary extends Component {
    constructor() {
        super();
        this.state = {
            over: false,
            pushed: false
            
        };

        this.scrollEvent     = this.scrollEvent.bind( this );
        this.set             = this.set.bind( this );
        this.handleOverEnter = this.handleOverEnter.bind( this );
        this.handleOverLeave = this.handleOverLeave.bind( this );
        
    };

    handleOverEnter() {
        this.setState( { over: true } );
    };

    handleOverLeave() {
        this.setState( { over: false } );
    };

    componentDidMount() {
        window.addEventListener( 'scroll', this.scrollEvent, { passive: true } );
    };

    componentWillUnmount() {
        window.removeEventListener( 'scroll', this.scrollEvent, { passive: true } );
    };

    removeActive() {
        if ( document.querySelector( '.active' ) ) {
            let actives = document.querySelector( '.active' );

            if ( actives !== null ) {
                actives.classList.remove( "active" );
            }
        }
    };

    scrollEvent() {
        let offset = 76;
        const offsets = [];
        const link_names = [];
        
        offsets.push( document.getElementById( "services" ).offsetTop );
        offsets.push( document.getElementById( "about-us" ).offsetTop );
        offsets.push( document.getElementById( "why-us" ).offsetTop );
        offsets.push( document.getElementById( "gallery" ).offsetTop );
        
        offsets.push( document.getElementById( "contacts" ).offsetTop );

        link_names.push( document.getElementById( "nav-services" ) );
        link_names.push( document.getElementById( "nav-about-us" ) );
        link_names.push( document.getElementById( "nav-why-us" ) );
        link_names.push( document.getElementById( "nav-gallery" ) );
        
        link_names.push( document.getElementById( "nav-contacts" ) );

        if ( ! this.state.pushed ) {
            if ( window.scrollY < offsets[0] ) {
                this.removeActive();
            }

            for (let i = 0; i < offsets.length; i++) {
                if ( window.scrollY + offset > offsets[i] ) {
                    this.removeActive();
                    link_names[i].classList.add( 'active' );
                }  
            }
        }
    };

    handleClick( e ) {
        this.setState( { pushed: true } )
        this.removeActive();

        let active = e.target;
        active.parentNode.classList.add( "active" );

        setTimeout( this.set, 800 );
    };

    set() {
        this.setState( { pushed: false } )
    };

    render() {
        

        return (
            <nav className="menu-primary">
                <ul className="nav">
                    <li className="nav-item" id="nav-services">
                        <Link to="services" title="Dienstleistungen" href="#services" onClick={ ( e ) => this.handleClick( e ) }>Dienstleistungen</Link>
                    </li>

                    <li className="nav-item" id="nav-about-us">
                        <Link to="about-us" title="Über uns" onClick={ ( e ) => this.handleClick( e ) } href="#about-us">Über uns</Link>
                    </li>

                    <li className="nav-item" id="nav-why-us">
                        <Link to="why-us" title="Ihre Vorteile" onClick={ ( e ) => this.handleClick( e ) } href="#why-us">Ihre Vorteile</Link>
                    </li>

                    <li onMouseOver={ this.handleOverEnter } onMouseOut={ this.handleOverLeave } id="nav-gallery" className={ "nav-item nav-item-has-children dropdown-hover " + ( this.state.over ? 'show' : '' ) }>
                        <Link to="gallery" title="Foto Gallerie" onClick={ ( e ) => this.handleClick( e ) } href="#gallery">Foto Gallerie</Link>

                        <span className="dropdown-toggle dropdown-custom-icon">
                            <span className="dropdown-icon">
                               
                            </span>
                        </span>

                        
                    </li>

                   

                    

                    

                    <li className="nav-item" id="nav-contacts">
                        <Link to="contacts" title="Kontakt" onClick={ ( e ) => this.handleClick( e ) } href="#contacts">Kontakt</Link>
                    </li>
                </ul>
            </nav>
        );
    };
};

export default HomeHeaderMenuPrimary;
