import React from 'react';

import FooterMenu from '../footer/FooterMenu';

const FooterWidget = () => {
    return (
      <div className="footer-widgets">
          <div className="footer-widget-area d-flex flex-wrap">
              <div className="widget">
                  <h5 className="widget-title">Über uns</h5>

                  <p>Die Firma TÜREGÜN Transport GmbH ist ein Schweizer Transportunternehmen mit Sitz in Basel.< br/>

</p>
              </div>

              <div className="widget">
                  <h5 className="widget-title">Menu</h5>

                  <FooterMenu />
              </div>

              <div className="widget">
                  <h5 className="widget-title">Kontakt</h5>

                  <p>Fahrettin Türegün</p>
                  <p>Mailandstrasse 31</p>
                  <p>4053 Basel</p>

                  <p>Tel: +41 61 331 42 58</p>
                  <p>Fax: +41 61 331 42 60</p>

                  <p><a href="mailto:info@turegun-transport.ch">info@turegun-transport.ch</a></p>

              </div>
          </div>
      </div>
    );
};

export default FooterWidget;
