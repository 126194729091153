import React from "react";

const ServiceInsideWidget = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Dienstleistungen:</h5>

        <div className="card-text">
          <li>Einlagern</li>
          <li>Kommisionierung</li>
          <li>Gütern Transporte </li>
          <li>Umzüge mit Montage</li>
          <li>Stückgutransporte</li>
          <li>Sonderfahrte</li>
          <li>Umzüg services</li>
        </div>
      </div>

      
    </div>
  );
};

export default ServiceInsideWidget;
