import React, { Component } from "react";


class PageTitleCommon extends Component {
  constructor() {
    super();
    this.state = {
      lang: "En",
    };

    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(event) {
    this.setState({
      lang: event.target.title,
    });
  }

  render() {
    return (
      <section
        id='page-title'
        className='block'
        style={{
          backgroundSize: 1920,
          backgroundImage: `url( ${require("../../assets/img/placeholder/team3.jpg")} )`,
        }}>
        <div className='wrapper h-100'>
          <div className='d-flex justify-content-between position-relative h-100'>
            <div className='align-self-center'>
              <div className='title'>
                <h1>{this.props.title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='page-title-bg-color'></div>
      </section>
    );
  }
}

export default PageTitleCommon;
