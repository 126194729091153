import React from 'react';

import WhyUsPdf from './WhyUsPdf';

const WhyUsContent = () => {
    return (
        <div className="wrapper">
            <div className="content">
                <div className="clearfix">
                    <div className="row gutter-width-lg with-pb-lg style-default">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">01</h4>
                            <p className="text-primary p-large bold">Qualität aus Erfahrung</p>
                            <img width="150"  class="logo-primary-light" src="assets/img/logo/logo-6-light.svg" alt="Logo"></img>
                            <p></p>
                            <p>Auch in Zukunft möchten wir für sie da sein und unseren Service in gewohnter Qualität anbieten.</p>

                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">02</h4>
                            <p className="text-primary p-large bold">Umweltschutz</p>
                            <p>Da wir unserer Verantwortung der Umwelt gegenüber bewusst sind, rüsten wir unsere Flotte immer moderner nach den Euro-Standards aus. Zusätzlich bilden wir unsere Mitarbeiterinnen und Mitarbeiter stets weiter um einen ökologischen Betrieb zu gewährleisten.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">03</h4>
                            <p className="text-primary p-large bold">Der Kunde</p>
                            <p>Nur ein Kunde der zufrieden ist, ist auch ein Kunde der wiederkommt. Deshalb setzen wir auf individuelle Transportlösungen welche mit höchster Sorgfalt und Professionalität durchgeführt werden.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">04</h4>
                            <p className="text-primary p-large bold">Das Transportgut</p>
                            <p>Ihre Güter unbeschadet von A nach B zu bringen ist eines unserer grundlegendsten Anliegen. Dafür setzen wir auf ein gut geschultes Team sowie top ausgerüstete LKWs sowohl für palettisierte Waren wie auch für ihren Umzug.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">05</h4>
                            <p className="text-primary p-large bold">Nachhaltigkeit und Verantwortung</p>
                            <p>Wir streben langfristige Partnerschaften. Unsere Dienstleistungen sind markt- und leistungsorientiert. </p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <WhyUsPdf />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUsContent;
