import React, { Component } from 'react';



class PageTitleHome extends Component {
    constructor() {
        super();
        this.state = {
            lang: 'En',
        };

        this.onChangeValue = this.onChangeValue.bind( this );
    };

    onChangeValue( event ) {
        console.log(event.target.title);
        this.setState( {
            lang: event.target.title,
        } );
    };

    render() {
        return (
            <section id="page-title" className="block" style={ { backgroundImage: `url( ${ require( '../../assets/img/placeholder/homeph.jpg' ) } )` } }>
                <div className='wrapper h-100'>
          <div className='d-flex justify-content-between position-relative h-100'>
            <div className='align-self-center'>
              <div className='title'>
                <h1>
                  {" "}
                  Willkommen zu <br /> TÜREGÜN Transport
                </h1>
              </div>

              <p className='spacer p-top-lg mb-0'>
                Die Firma TÜREGÜN Transport GmbH ist ein Schweizer
                Transportunternehmen mit Sitz in Basel.
              </p>
            </div>


                        <div className="lang-position">
                            <nav className="languages">
                                
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="page-title-bg-color"></div>
            </section>
        );
    };
};

export default PageTitleHome;
