import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';

class HeaderMenuPrimary extends Component {
    constructor( props ) {
        super();
        this.state = {
            over: false,
        };
    };

    handleOver = () => {
        this.setState( { over: ! this.state.over } );
    };

    render() {
        const { over } = this.state;

        return (
            <nav className="menu-primary">
                <ul className="nav">
                    <li className="nav-item">
                        <Link to={ process.env.PUBLIC_URL + "/" } title="Home">Home</Link>
                    </li>

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/services" ) || window.location.pathname.includes( "/service-inside" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/services" } title="Services">Dienstleistungen</Link>
                    </li>

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/about-us" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/about-us" } title="About ussss">Über uns</Link>
                    </li>

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/why-us" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/why-us" } title="Why us">Ihre Vorteile</Link>
                    </li>

                    <li onMouseOver={ this.handleOver } onMouseOut={ this.handleOver } className={ "nav-item nav-item-has-children dropdown-hover " + ( window.location.pathname.includes( "/gallery" ) || window.location.pathname.includes( "/gallery-inside" ) ? "current-nav-item" : "" ) + ( this.state.over ? 'show' : '' ) }>
                        <Link to={ process.env.PUBLIC_URL + "/gallery" } title="Gallery">Foto Gallerie</Link>

                        

                        <Fade in={ over } timeout={ 400 }>
                            <ul className="dropdown-menu" style={ { display: 'block' } }>
                               
                            </ul>
                        </Fade>
                    </li>

                    

                    

                    

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/contacts" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/contacts" } title="Contacts">Kontakt</Link>
                    </li>
                </ul>
            </nav>
        );
    };
};

export default HeaderMenuPrimary;
