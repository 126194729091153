import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Collapse } from '@material-ui/core';

const ModalMenuHome = () => {
    const [open, setOpen] = useState( false );

    const handleClick = () => {
        setOpen( ( prev ) => ! prev );
    };

    const modalOff = () => {
        let e = document.getElementById('close-modal')

        if ( e ) {
            e.click();
        }
    };

    return (
        <nav className="menu-primary">
            <ul className="list-unstyled">
                <li className="nav-item">
                    <Link to="services" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="Services" href="#">Dienstleistungen</Link>
                </li>

                <li className="nav-item">
                    <Link to="about-us" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="About us" href="#">Über uns</Link>
                </li>

                <li className="nav-item">
                    <Link to="why-us" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="Why us" href="#">Ihre Vorteile</Link>
                </li>

                <li className={"nav-item nav-item-has-children dropdown-child-click-effect " + ( open ?  "show" : '' ) }>
                    <Link to="gallery" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="Gallery" href="#">Foto Gallerie</Link>

                    <span onClick={ handleClick } className="dropdown-toggle dropdown-custom-icon" style={ { color: '#414258' } }>
                        <span className="dropdown-icon">
                            <i className="fas fa-caret-down"></i>
                        </span>
                    </span>

                    <Collapse in={ open } timeout={ 400 }>
                        
                    </Collapse>
                </li>

                

                

                

                <li className="nav-item">
                    <Link to="contacts" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="Contacts" href="#">Kontakt</Link>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuHome;
