import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class ModalMenuPrimary extends Component {
    constructor( props ) {
        super();
        this.state = {
            open: false,
        };
    };

    handleClick = () => {
        this.setState( { open: ! this.state.open } )
    };

    render() {
        return (
            <nav className="menu-primary">
                <ul className="list-unstyled">
                    <li className="nav-item ">
                        <Link to={ process.env.PUBLIC_URL + "/" } title="Home">Home</Link>
                    </li>

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/services" ) || window.location.pathname.includes( "/service-inside" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/services" } title="Services">Dienstleistungen</Link>
                    </li>

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/about-us" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/about-us" } title="About us">Über uns</Link>
                    </li>

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/why-us" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/why-us" } title="Why us">Ihre Vorteile</Link>
                    </li>

                    

                    

                    

                    

                    <li className={ "nav-item " + ( window.location.pathname.includes( "/contacts" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/contacts" } title="Contacts">Kontakt</Link>
                    </li>
                </ul>
            </nav>
        );
    }

};

export default ModalMenuPrimary;
