import React from 'react';

const AboutUsSecondary = () => {
    return (
        <div className="spacer m-top-lg">
            <p><h3>Geschichte:</h3></p>
            <p>Im Oktober 2000 wurde die Türegün Transport GmbH von den beiden Brüdern Osman und Fahrettin Türegün in der Schweiz mit Sitz in Basel-Stadt gegründet.
Unsere Ursprünge liegen im Stückguttransport. Fortschrittlich konnten wir unser Aufgabengebiet im Laufe der Jahre mit dem allgemeinen Transport sowie mit Komplettladungen, Sonderfahrten, Umzugsservice und Lagerservice erweitern. Das Jahr 2019 war geprägt von einer grossen Veränderung, wir konnten die Firma A. Lauda exklusive der Umzugsabteilung übernehmen.
Mit Freude übernahmen wir zwei Jahre später ergänzend die Umzugsabteilung der A. Lauda GmbH. Unsere Firma Türegün Transport GmbH konnte so an zusätzlicher Verstärkung gewinnen.
</p>
        </div>
    );
};

export default AboutUsSecondary;
